var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as QuestionMarkIcon } from '@icons/wolfkit-light/question-circle-light.svg';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import Icon from '@shared/ui/icons/Icon';
const QuestionMarkTooltip = (_a) => {
    var { iconColor = undefined, size = 'medium' } = _a, props = __rest(_a, ["iconColor", "size"]);
    const theme = useTheme();
    const iconSize = useMemo(() => {
        let s = 16;
        if (size === 'small') {
            s = 12;
        }
        else if (size === 'medium') {
            s = 16;
        }
        return s;
    }, [size]);
    return (_jsx(Tooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { children: _jsx(Icon, { size: iconSize, color: iconColor || theme.palette.text.secondary, IconComponent: QuestionMarkIcon }) })));
};
export default QuestionMarkTooltip;
