import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as BinanceIconComponent } from '@icons/exchanges/binance-name.svg';
import { ReactComponent as CommexIconComponent } from '@icons/exchanges/commex-name.svg';
import { ReactComponent as BitmexIconComponent } from '@icons/exchanges/bitmex-name.svg';
import { ReactComponent as CoinsferaIconComponent } from '@icons/exchanges/coinsfera-name.svg';
import { ReactComponent as ShieldIconComponent } from '@icons/wolfkit-light/shield-with-circle-light.svg';
const CommexIcon = styled(CommexIconComponent)(() => ({
    width: 92,
    height: 24,
    rect: {
        opacity: 1,
    },
}));
const BitmexIcon = styled(BitmexIconComponent)(() => ({
    width: 88,
    height: 24,
}));
const CoinsferaIcon = styled(CoinsferaIconComponent)(() => ({
    width: 108,
    height: 24,
}));
const BinanceNameIcon = styled(BinanceIconComponent)(() => ({
    height: 24,
    width: 120,
}));
const ShieldIcon = styled(ShieldIconComponent)(() => ({
    width: 32,
    height: 32,
}));
const ErrorIconContainer = styled.div(props => ({
    width: 70,
    height: 70,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: props.theme.palette.error.main,
    backgroundColor: '#FFF6F6',
    cursor: 'default',
    pointerEvents: 'none',
    fontSize: 46,
    fontWeight: 600,
}));
const ErrorIcon = () => _jsx(ErrorIconContainer, { children: "!" });
const IconContainerComponent = styled.div(() => ({
    height: 24,
    textAlign: 'center',
}));
const IconContainer = ({ icon, }) => (_jsx(IconContainerComponent, { children: icon }));
export { BinanceNameIcon, CoinsferaIcon, BitmexIcon, CommexIcon, IconContainer, ShieldIcon, ErrorIcon, };
